export function rating() {
    function setupInteractiveRating() {
        const stars = document.querySelectorAll('.rating-stars-hover span');
        const ratingValue = document.getElementById('rating-value');
        const ratingInput = document.getElementById('rating-input');

        if (stars.length === 0 || !ratingValue || !ratingInput) {
            return; // Если элементы не найдены, выходим из функции
        }

        if (stars) {
            stars.forEach(star => {
                star.addEventListener('mouseover', function () {
                    resetStars(stars);
                    highlightStars(stars, this.dataset.value);
                });

                star.addEventListener('mouseout', function () {
                    resetStars(stars);
                    highlightStars(stars, ratingInput.value);
                });

                star.addEventListener('click', function () {
                    ratingInput.value = this.dataset.value;
                    ratingValue.innerText = this.dataset.value;
                    resetStars(stars);
                    highlightStars(stars, ratingInput.value);
                });
            });
        }
    }

    function setupStaticRating() {
        const ratings = document.querySelectorAll('.rating-value-number');

        ratings.forEach(ratingValueNumber => {
            const starsContainer = ratingValueNumber.closest('.rating-container');
            if (starsContainer) {
                const stars = starsContainer.querySelectorAll('.rating-review-stars-hover span');
                // Закрашиваем звездочки в зависимости от значения рейтинга
                highlightStars(stars, ratingValueNumber.innerText);
            }
        });
    }

    function resetStars(stars) {
        stars.forEach(star => {
            star.classList.remove('active');
        });
    }

    function highlightStars(stars, value) {
        stars.forEach(star => {
            if (parseInt(star.dataset.value, 10) <= parseInt(value, 10)) {
                star.classList.add('active');
            }
        });
    }



    if (document.querySelector('.rating-stars-hover')) {
        setupInteractiveRating();
        setupStaticRating();
    }
}