export function symbolLength() {
    document.querySelectorAll('.offer-box__field').forEach(function (field) {
        const input = field.querySelector('.offer-box__input');
        const lengthSpan = field.querySelector('.offer-box__length');
        const maxLengthSpan = field.querySelector('.offer-box__max-length');
        const maxLength = parseInt(maxLengthSpan.textContent, 10);

        if (input) {
            input.addEventListener('input', function () {
                const valueLength = input.value.length;
                lengthSpan.textContent = valueLength;
    
                if (valueLength > 0 && valueLength < maxLength) {
                    lengthSpan.classList.add('c-dblue');
                    maxLengthSpan.classList.add('c-dblue');
                    lengthSpan.classList.remove('c-bgl', 'c-blue');
                    maxLengthSpan.classList.remove('c-bgl', 'c-blue');
                } else if (valueLength === maxLength) {
                    lengthSpan.classList.add('c-blue');
                    maxLengthSpan.classList.add('c-blue');
                    lengthSpan.classList.remove('c-dblue', 'c-bgl');
                    maxLengthSpan.classList.remove('c-dblue', 'c-bgl');

                } else {
                    lengthSpan.classList.remove('c-dblue', 'c-blue');
                    maxLengthSpan.classList.remove('c-dblue', 'c-blue');
                    lengthSpan.classList.add('c-bgl');
                    maxLengthSpan.classList.add('c-bgl');

                }
            });
        }
    });
}