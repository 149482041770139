import { Fancybox } from "@fancyapps/ui";

export function fancybox() {
    
    // Инициализация Fancybox для всех галерей
    Fancybox.bind('[data-fancybox^="gallery"]', {
        // Настройки Fancybox
        loop: true,
        buttons: [
            "zoom",
            "share",
            "slideShow",
            "fullScreen",
            "download",
            "thumbs",
            "close"
        ]
    });
}
