export function searchElement() {
    const therapistsFilterSearchBtn = document.getElementById('therapists-filters-search-btn');
    if (therapistsFilterSearchBtn) {
        // При клике на кнопку с id therapists-filters-search-btn
        therapistsFilterSearchBtn.addEventListener('click', function () {
            // Переключаем активный класс для кнопки и показываем/скрываем блок фильтров
            this.classList.toggle('active');
            document.getElementById('therapists-filters-search-block').classList.toggle('d-none');
        });
    }

    const therapistsFilterSearchClose = document.getElementById('search-element-close');
    if (therapistsFilterSearchClose) {
        // При клике на элемент с id search-element-close
        therapistsFilterSearchClose.addEventListener('click', function () {
            // Находим родителя элемента с классом search-element и добавляем ему класс d-none
            const searchElement = this.closest('.search-element');

            // Очищаем текстовое поле внутри search-element
            const inputField = searchElement.querySelector('input');
            if (inputField) {
                inputField.value = '';
            }
        });
    }
}
