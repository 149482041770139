import { fieldsText } from './text.js'
import { fieldsEmail } from './email.js'
import { fieldsPhone } from './phone.js'
import { fieldsPassword } from './password.js'
import { fieldsPhoto } from './photo.js'
import { fieldsSelect } from './select.js'
import { birthday } from './birthday.js'
import { deleteAccount } from './deleteAccount.js';
import { fileUpload } from './fileUpload.js'
import { rating } from './rating.js'
import { symbolLength } from './offer/symbolLength.js'
import { addFields } from './offer/addFields.js'







export function validationForms() {
    'use strict';
    // Функция для валидации форм
    function validateForms() {
        // Активация валидации Bootstrap для всех форм с классом 'needs-validation'
        document.querySelectorAll('.needs-validation').forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');

                // Находим все инпуты в текущей форме, которые требуют валидации
                const inputs = form.querySelectorAll('.form__input');

                inputs.forEach(function (input) {
                    // Находим родителя инпута с классом form-item
                    const formItem = input.closest('.form-item');
                    if (!input.checkValidity()) {
                        // Если валидация не прошла, находим сообщение об ошибке
                        const errorMessage = formItem.querySelector('.invalid-feedback');
                        input.closest('.form-item').classList.add('error');
                        if (errorMessage) {
                            // Отображаем сообщение об ошибке
                            errorMessage.style.display = 'block';
                        }
                    } else {
                        // Если валидация прошла, скрываем сообщение об ошибке
                        const errorMessage = formItem.querySelector('.invalid-feedback');
                        input.closest('.form-item').classList.remove('error');

                        if (errorMessage) {
                            errorMessage.style.display = 'none';
                        }
                    }
                });
            }, false);
        });
    }

    // Проверяем, есть ли на странице формы, и вызываем нужные функции
    if (document.querySelector('.needs-validation')) {
        fieldsText();
        fieldsEmail();
        fieldsPhone();
        fieldsPassword();
        fieldsPhoto();
        fieldsSelect();
        birthday();
        validateForms();
    }
    // Удаление аккаунта, модальное окно, выбор другой причины
    deleteAccount();

    // добавление нескольких файлов
    fileUpload();

    // оценка терапевта
    rating();

    // количество символов в оффере
    symbolLength();

    // Добавление новых полей в редактировании оффера
    addFields();
}