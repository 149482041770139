export function bannerElements() {
    document.querySelectorAll('.banner__close').forEach(function(closeButton) {
        closeButton.addEventListener('click', function() {
            // Находим родительский элемент с классом banner
            var parentBanner = this.closest('.banner');
            if (parentBanner) {
                parentBanner.classList.add('d-none');
            }
        });
    });
}