export function eventsTabs() {
    // Получаем все элементы вкладок и содержимого
    const tabs = document.querySelectorAll('.history-tabs div');
    const content = document.querySelector('.history-tabs-contents div');

    // Добавляем обработчик клика для каждой вкладки
    tabs.forEach((tab, index) => {
        tab.addEventListener('click', () => {
            // Проверяем, что элемент содержимого существует
            if (content) {
                // Устанавливаем id для элемента содержимого на основе индекса вкладки
                content.id = `content-${index + 1}`; // Индексы начинаются с 0, но id начинается с 1
                content.classList.add('tab-pan', 'active', 'show');
            }
        });
    });
}
