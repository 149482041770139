export function addActiveLink() {
    // Получить текущий URL
    var currentUrl = window.location.pathname

    // Определить все элементы меню
    var menuItems = document.querySelectorAll('.lk-menu__ul .lk-menu__li a')

    // Пройти по каждому элементу меню
    menuItems.forEach(function (link) {
        // Проверить, совпадает ли href ссылки с текущим URL
        if (link.getAttribute('href') === currentUrl) {
            // Добавить класс 'active' к элементу <a>
            link.classList.add('active')
        }
    })
}