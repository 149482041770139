export function fieldsPhoto() {
    const addPhotoBlocks = document.querySelectorAll('.add-photo');

    addPhotoBlocks.forEach(block => {
        const fileInput = block.querySelector('.add-photo__input');
        const thumbnail = block.querySelector('.add-photo__thumbnail');
        const attachButton = block.querySelector('.add-photo__button');
        const errorFeedback = block.querySelector('.invalid-feedback.error');
        const photoLabel = block.querySelector('.add-photo__label');
        const removeButton = block.querySelector('.add-photo__remove');
        
        let isProcessing = false;

        photoLabel.addEventListener('click', function(event) {
            event.stopPropagation();
            event.preventDefault();

            if (isProcessing) return;
            isProcessing = true;

            if (!event.target.closest('.add-photo__remove')) {
                fileInput.click();
            }

            fileInput.addEventListener('change', function() {
                isProcessing = false;
                handleFileChange(); // Вызов функции обработки файла
            }, { once: true });
        });

        function handleFileChange() {
            const file = fileInput.files[0];
            const maxSize = 10 * 1024 * 1024; // 10 MB
            const allowedTypes = ['image/jpeg', 'image/png'];


            if (file.size > maxSize) {
                errorFeedback.innerText = 'Файл слишком большой. Максимальный размер 10MB.';
                errorFeedback.style.display = 'block';
                fileInput.setCustomValidity('invalid');
                return;
            }

            if (!allowedTypes.includes(file.type)) {
                errorFeedback.innerText = 'Допустимые форматы: JPG, JPEG, PNG.';
                errorFeedback.style.display = 'block';
                fileInput.setCustomValidity('invalid');
                return;
            }

            errorFeedback.innerText = '';
            errorFeedback.style.display = 'none';
            fileInput.setCustomValidity('');

            const reader = new FileReader();
            reader.onload = function(event) {
                thumbnail.src = event.target.result;
                thumbnail.classList.remove('add-photo__thumbnail_hidden');
                photoLabel.classList.add('added');
            };

            reader.readAsDataURL(file);
        }

        removeButton.addEventListener('click', function(event) {
            event.stopPropagation();
            event.preventDefault();

            thumbnail.src = '/assets/image/users/users/users.png';
            thumbnail.classList.add('add-photo__thumbnail_hidden');

            photoLabel.classList.remove('added');

            errorFeedback.innerText = '';
            errorFeedback.style.display = 'none';
            fileInput.setCustomValidity('');

            fileInput.value = '';
        });

        attachButton.addEventListener('click', function(event) {
            event.stopPropagation();
            event.preventDefault();
            photoLabel.click();
        });
    });
}
