export function fieldsText() {
    // Ограничение ввода для имени и фамилии до 100 символов
    var textInputs = document.querySelectorAll('input[type="text"]');
    if (textInputs.length > 0) {
        textInputs.forEach(function (input) {
            input.addEventListener('input', function () {
                if (this.value.length > 100) {
                    this.value = this.value.substring(0, 100);
                }
            });
        });
    }
}