import ru from 'intl-tel-input/build/js/i18n/ru'; // Импортируем локализацию на русский язык

// Инициализация плагина
export function fieldsPhone() {
    var phoneInput = document.querySelector("#id_phone");

    if (phoneInput) {
        var iti = window.intlTelInput(phoneInput, {
            initialCountry: "auto",
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/js/utils.js",
            separateDialCode: true,
            nationalMode: true,
            customContainer: "intl-tel-input",
            preferredCountries: ["us", "gb"],
            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                return "например, " + selectedCountryPlaceholder;
            },
            i18n: ru,
            initialCountry: "ru",
            formatOnDisplay: true
        });
    }
}
