export function deleteAccount() {
    // Открытие блока с textarea при выборе пункта списка Другая причина
    if ($('#reason')) {
        $('#reason').on('change', function () {
            $('#button_reason').removeAttr('disabled');
            var selectedValue = $(this).val();
            if (selectedValue === 'Другая причина') {
                $('#textarea_another_reason').removeClass('d-none');
            } else {
                $('#textarea_another_reason').addClass('d-none');
                $('#another_reason').val(''); // Очищаем textarea, если выбрана другая опция
            }
        });
    }


    // Записываем value из textarea и value последнего option списка

    if ($('#another_reason')) {
        $('#another_reason').on('input', function () {
            var textareaValue = $(this).val();
            var lastOption = $('#reason option').last();
            lastOption.val(textareaValue);
            // Обновление Select2 путем его уничтожения и повторной инициализации
            $('#reason').select2('destroy').select2();
        });
    }


    // Открытие модального окна Подтверждения удаления аккаунта при клике на .button_reason_deletion

    if ($('#button_reason')) {
        $('#button_reason').on('click', function () {
            $('#modalDeleteAccount').modal('hide');
            $('#modalConfirmDeleteAccount').modal('show');
        });
    }
}
