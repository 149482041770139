import { initSwipers } from '../../nunjucks/partials/block/slider/slider.js';
import { sliceTherapistsDescription } from '../../nunjucks/partials/block/therapists-card/therapistsCard.js';
import { sliceHeaderUserName } from '../../nunjucks/partials/block/header/header.js';
import { validationForms } from '../../nunjucks/partials/components/fields/fields.js';
import { btnLoadMore } from '../../nunjucks/partials/components/buttons/btn-load-more.js';
import { eventsTabs } from '../../nunjucks/partials/components/tabs/tabs.js';
import { tooltip } from '../../nunjucks/partials/components/tooltip/tooltip.js';
import { searchElement } from '../../nunjucks/partials/block/search/search.js';
import { bannerElements } from '../../nunjucks/partials/block/banner/banner.js';
import { stepsForm } from '../../nunjucks/partials/block/stepsForm/stepsForm.js';
import { addActiveLink } from '../../nunjucks/partials/block/lk-menu/activeLink.js';
import { fancybox } from '../../nunjucks/partials/block/fancybox/fancybox.js';
import { generateThumbnail } from '../../nunjucks/partials/block/revRecBlock/generateThumbnail.js';
import { initCalendar } from '../../nunjucks/partials/components/calendar/calendar.js';








export function initComponents() {
    // инициализация слайдеров
    initSwipers();

    // Обрезаем текст в карточке терапевта, описание
    sliceTherapistsDescription();

    // Обрезаем текст в панели юзера, имя и фамилию
    sliceHeaderUserName();

    // Валидация 
    validationForms();

    // Поиск на странице Терапевты
    searchElement();

    // кнопка показать еще
    btnLoadMore();

    // баннер в ЛК
    bannerElements();

    // добавление класса active к текущему разделу меню
    addActiveLink();

    // меняем id табов при клике на Все, Проработки, Диагностика
    eventsTabs();

    // тултипы bootstrap
    tooltip();

    // галерея
    fancybox();

    // генерация миниатюр картинок в видео отзывах/рекомендациях

    generateThumbnail();

    // календарь для записи
    initCalendar();


    // пошаговая форма
    stepsForm();
}