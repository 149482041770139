export function fieldsPassword() {
    // Валидация пароля
    var passwordInput = document.querySelector('#id_password1');
    if (passwordInput) {
        passwordInput.addEventListener('input', function () {
            var password = this.value;
            var passwordError = document.querySelector('#passwordError');
            var passwordPattern = /^(?=.*[!@#$%]).{8,}$/;

            if (!passwordPattern.test(password)) {
                passwordError.textContent = 'Пароль должен состоять из 8 или более символов и содержать специальные символы !@#$%';
                this.setCustomValidity('Пароль должен состоять из 8 или более символов и содержать специальные символы !@#$%');
            } else {
                passwordError.textContent = '';
                this.setCustomValidity('');
            }
        });
    }

    // Валидация повторного пароля
    var confirmPasswordInput = document.querySelector('#id_password2');
    if (confirmPasswordInput) {
        confirmPasswordInput.addEventListener('input', function () {
            var password = document.querySelector('#id_password1').value;
            var confirmPassword = this.value;

            if (password !== confirmPassword) {
                this.setCustomValidity('Вы неверно повторили пароль');
            } else {
                this.setCustomValidity('');
            }
        });
    }

    // Показать пароль
    document.querySelectorAll('.show-password').forEach(function (showPasswordButton) {
        // Добавляем обработчик события клика
        showPasswordButton.addEventListener('click', function () {
            // Находим предыдущий элемент в DOM структуре
            var previousInput = showPasswordButton.previousElementSibling;

            // Проверяем, что предыдущий элемент - это <input> с типом 'password' или 'text'
            if (previousInput && previousInput.tagName === 'INPUT' && (previousInput.type === 'password' || previousInput.type === 'text')) {
                // Меняем тип с 'password' на 'text' и обратно
                if (previousInput.type === 'password') {
                    previousInput.type = 'text';
                } else {
                    previousInput.type = 'password';
                }
            }
        });
    });


}