export function initSwipers() {
    var swiper = new Swiper(".swiper.screen-slider__swiper", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination"
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
        },
    });
    var swiper2 = new Swiper(".swiper.therapists-slider__swiper", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoHeight: true,
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        },
    });
    var swiper3 = new Swiper(".swiper.terapist-screen__swiper", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoHeight: true,
        initialSlide: 1,  // Устанавливаем второй слайд начальным (индекс 1)
        loop: true,       // Включаем зацикливание
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20
            }
        },
    });
}