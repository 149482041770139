export function btnLoadMore() {
  // Ищем все элементы с классом box-load-more
  document.querySelectorAll('.box-load-more').forEach(function (box) {
    // Скрываем все внутренние элементы
    let items = box.children;
    for (let i = 0; i < items.length; i++) {
      items[i].style.display = 'none';
    }

    // Получаем значение data-visible и показываем первые X элементов
    let visibleCount = parseInt(box.getAttribute('data-visible'), 10);
    for (let i = 0; i < visibleCount; i++) {
      if (items[i]) {
        items[i].style.display = '';
      }
    }

    // Проверяем, нужно ли скрыть кнопку изначально
    let hiddenItems = Array.from(box.children).filter(function (item) {
      return item.style.display === 'none';
    });
    let button = box.nextElementSibling.querySelector('.button-load-more');
    if (hiddenItems.length === 0 && button) {
      button.style.display = 'none';
    }
  });

  // Добавляем обработчики кликов на кнопки button-load-more
  document.querySelectorAll('.button-load-more').forEach(function (button) {
    button.addEventListener('click', function () {
      // Ищем предыдущий элемент с классом box-load-more
      let box = this.parentElement.previousElementSibling;
      if (box && box.classList.contains('box-load-more')) {
        // Читаем значение data-visible
        let visibleCount = parseInt(box.getAttribute('data-visible'), 10);
        // Ищем все скрытые элементы внутри box
        let hiddenItems = Array.from(box.children).filter(function (item) {
          return item.style.display === 'none';
        });
        // Показываем следующие X элементов
        for (let i = 0; i < visibleCount; i++) {
          if (hiddenItems[i]) {
            hiddenItems[i].style.display = '';
          }
        }
        // Проверяем, остались ли еще скрытые элементы
        hiddenItems = Array.from(box.children).filter(function (item) {
          return item.style.display === 'none';
        });
        // Если скрытых элементов больше нет, скрываем кнопку
        if (hiddenItems.length === 0) {
          this.style.display = 'none';
        }
      }
    });
  });
}
