// Импортируем компоненты

// Библиотека для выбора даты рождения
import flatpickr from 'flatpickr';
import 'flatpickr/dist/l10n/ru.js'; // Импортируйте локализацию

import { initComponents } from './components.js';
import { postUserRegistration } from './api.js';
import { handleFormSubmit } from './handleFormSubmit.js';

import { showNotification } from '../../nunjucks/partials/block/modal-notification/showNotification.js';
window.showNotification = showNotification;

async function init() {
    try {
        // Получение пользователя по id
        postUserRegistration();

    } catch (error) {
        console.error('Ошибка при выполнении операции:', error);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    initComponents();
    // init();
    handleFormSubmit();
});
