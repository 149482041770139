export function fieldsEmail() {
    // Валидация email
    var emailInput = document.querySelector('#id_email');
    if (emailInput) {
        emailInput.addEventListener('input', function () {
            var email = this.value;
            var emailError = document.querySelector('#emailError');
            var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (email.length > 0 && email.length < 5) {
                emailError.textContent = 'Минимальное количество символов - 5';
                this.setCustomValidity('Минимальное количество символов - 5');
            } else if (email.length > 50) {
                emailError.textContent = '';
                this.setCustomValidity('');
            } else if (!emailPattern.test(email)) {
                emailError.textContent = 'Недопустимый формат. Пример: example@mail.ru';
                this.setCustomValidity('Недопустимый формат. Пример: example@mail.ru');
            } else {
                emailError.textContent = '';
                this.setCustomValidity('');
            }
        });
        
        // Ограничение ввода для email до 50 символов
        emailInput.addEventListener('input', function () {
            if (this.value.length > 50) {
                this.value = this.value.substring(0, 50);
            }
        });
    }
}