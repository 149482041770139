export function generateThumbnail() {
    var videoContainers = document.querySelectorAll('.video-box-icon_min');
    if (videoContainers) {
        videoContainers.forEach(function (container) {
            var videoSrc = container.getAttribute('href'); // Получаем URL видео из атрибута href

            // Проверяем, есть ли уже изображение в контейнере
            if (container.querySelector('img')) return;

            // Создание элемента video
            var video = document.createElement('video');
            video.src = videoSrc;
            video.currentTime = 1; // Устанавливаем время на 1 секунду (вы можете изменить это значение для другого кадра)
            video.crossOrigin = 'anonymous';

            // Создание элемента canvas для захвата кадра
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var img = new Image();

            video.addEventListener('loadeddata', function () {
                if (video.readyState >= 2) { // Проверяем, что видео загружено
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    img.src = canvas.toDataURL();
                    img.alt = 'Video Thumbnail';
                    img.className = 'w-100 h-100 object-fit-cover'; // Класс для стилизации
                    container.innerHTML = ''; // Очищаем контейнер
                    container.appendChild(img);
                }
            });
        });
    }
}