export function fieldsSelect() {
    $('.select2').select2({
        placeholder: "Выберите ваш часовой пояс",
        width: '100%',
        dropdownCssClass: 'select2-dropdown-custom' // Добавим класс для стилизации
    });

    // список с годами В терапии

    const startYear = 1980;
    const endYear = new Date().getFullYear();
    const selectElement = document.querySelector('.select2__year');

  // Генерация всех годов
    if (selectElement) {
      for (let year = startYear; year <= endYear; year++) {
        const option = document.createElement('option');
        option.value = year;
        option.textContent = year;
        selectElement.appendChild(option);
      }
  }
}